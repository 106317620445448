import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { approveRejectRequest } from "../../services/User/UserService";
import { notifyError, notifyTopRight } from "../common/Toaster";



const ReasonModal = ({ show, id, type, table, onHide }) => {
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({ reason: "" });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const payload = {
          zapperId: id,
          status: "Rejected",
          reason: reason,
        };
        await approveRejectRequest(payload);
        notifyTopRight("Rejected Successfully.");
        table();
        onHide();
        setReason("");
      } catch (error) {
        notifyError(error.response?.data?.message || "An error occurred");
        console.error("Error:", error.response || error);
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errorObj = { reason: "" };

    if (!reason) {
      errorObj.reason = "This field is required";
      isValid = false;
    }

    setErrors(errorObj);
    return isValid;
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <form onSubmit={handleSubmit}>
        <div className="modal-header">
          <h4 className="modal-title fs-20">Reason</h4>
        </div>
        <div className="modal-body">
          <div className="form-group mb-3">
            <label className="text-black font-w500">Reason</label>
            <textarea
              rows={5}
              className="form-control"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setErrors({ ...errors, reason: "" });
              }}
            />
            {errors.reason && (
              <div className="text-danger fs-12">{errors.reason}</div>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={onHide} className="btn btn-danger">
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReasonModal;
