import moment from "moment";
import React, { useState, useEffect } from "react";
import { Table, Badge, Dropdown } from "react-bootstrap";

import { useLocation } from "react-router-dom";
import {
  approveRejectRequest,
  getVerificationRequests,
} from "../../services/User/UserService";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
import { notifyError, notifyTopRight } from "../common/Toaster";
import ReasonModal from "../modal/ReasonModal";

const RequestList = (props) => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [requests, setRequests] = useState([]);
  const [listLength, setListLength] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [gender, setGender] = useState("all");
  const [currentPage, setCurrentPage] = useState(
    location?.state?.page ? location?.state?.page : 0
  );
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [id, setId] = useState("");
  const [type, setType] = useState("id");
  const [imageForView, setImageForView] = useState("");
  const [view, setView] = useState(false);
  const [search, setSearch] = useState("");

  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const fetchRequests = async () => {
    setLoader(true);
    try {
      const response = await getVerificationRequests(
        currentPage,
        limit,
        search
      );
      setRequests(response.data.data?.users);
      const total = response.data.data.total;
      setPageCount(Math.ceil(total / limit));
      setListLength(total);
      setLoader(false);
    } catch (error) {
      console.log(error, "error");
      setLoader(false);
    }
  };

  const onApprove = async (id) => {
    setLoader(true);
    try {
      const payload = { zapperId: id, status: "Accepted",reason:"" };
      const response = await approveRejectRequest(payload);
      fetchRequests();
      notifyTopRight(response.data?.message);
      setLoader(false);
    } catch (error) {
      notifyError(error.response?.data?.message);
      console.log(error.response, "error");
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchRequests();
  }, [currentPage, search]);

  return loader ? (
    <Spinner />
  ) : (
    <>
      <div className="d-flex align-items-center border-bottom gap-2 pb-3">
        {/* <div className="col-6"></div> */}
      </div>
      <Table responsive>
        <thead style={{ color: "black" }}>
          <tr>
            <th>
              <strong>NAME</strong>
            </th>
            <th>
              <strong>EMAIL</strong>
            </th>

            <th>
              <strong>PHONE NUMBER</strong>
            </th>
            <th>
              <strong>Document</strong>
            </th>
            
            <th>
              <strong>created at</strong>
            </th>
            <th>
              <strong>STATUS</strong>
            </th>
            <th>
              <strong>ACTION</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          {requests?.map((item, i) => (
            <tr key={i}>
              <td
                onClick={() =>
                  props.history.push("/user-details", {
                    state: {
                      data: item?._id,
                      selectedTab: "verification requests",
                      page: currentPage,
                    },
                  })
                }
                className="pointer"
              >
                {item?.name}
              </td>
              <td>{item?.email}</td>

              <td>
                {item.countryCode && "+" + item.countryCode} {item?.phoneNumber}
              </td>
              <td className="pointer">
                <img
                  src={item?.documents ? item?.documents[0]?.file : ""}
                  alt="img"
                  width={100}
                  height={80}
                  className="rounded"
                  //   onClick={() => {
                  //     setImageForView(
                  //       imgBaseURl + item?.verificationId?.frontUrl
                  //     );
                  //     setView(true);
                  //   }}
                />
              </td>
              <td>{moment(item?.createdAt).format("MMM Do YYYY, h:mm A")}</td>

              <td
                onClick={() =>
                  props.history.push("/user-details", {
                    state: {
                      data: item?._id,
                      selectedTab: "verification requests",
                      page: currentPage,
                    },
                  })
                }
                className="pointer"
              >
                <Badge bg="" className="badge-warning">
                  {item?.status}
                </Badge>
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dark light"
                    className="light sharp btn btn-dark i-false"
                  >
                    {svg1}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onApprove(item?._id)}>
                      Approve
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowRejectModal(true);
                        setId(item?._id);
                      }}
                    >
                      Reject
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {requests?.length === 0 && !loader && (
        <div className="justify-content-center d-flex my-5">
          Sorry, Data Not Found!
        </div>
      )}
      {requests?.length !== 0 && (
        <div className="card-footer clearfix">
          <div className="d-block d-sm-flex justify-content-between align-items-center">
            <div className="dataTables_info">
              Showing {currentPage * limit + 1} to{" "}
              {listLength > (currentPage + 1) * limit
                ? (currentPage + 1) * limit
                : listLength}{" "}
              of {listLength} entries
            </div>
            <Pagination
              pageCount={pageCount}
              pageValue={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>
      )}
      
      {showRejectModal && (
        <ReasonModal
          show={showRejectModal}
          id={id}
          type={type}
          table={fetchRequests}
          onHide={() => setShowRejectModal(false)}
        />
      )}
    </>
  );
};

export default RequestList;
